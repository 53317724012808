import React from 'react';
import EvansDinoJumpingGame from './EvansDinoJumpingGame.js';

function App() {
  return (
    <div className="App">
      <EvansDinoJumpingGame />
    </div>
  );
}

export default App;